import React from 'react';
import {} from "reactstrap";
import imgbanner from '../../images/Imagerow.png';

const styles = {
  banner: {
    backgroundImage: `url(${imgbanner})`,
    height: '60vh',
    backgroundSize: 'cover',
    "@media (max-width: 767px)":{
      height: '10vh',
    }
  }
}

const ImgBanner = () => {


  return(
    <div style={styles.banner}>

     
    
    </div>
  );
};

export default  ImgBanner;
